<template>
  <div id="super-loan">
    <header :class="headerClassName">
      <div class="loan-money">
        {{
          (topProduct.moneySection && topProduct.moneySection.split("-")[1]) || 80000
        }}
      </div>
      <div
          class="apply-button"
          @click="toApply(topProduct)"
          v-throttle="2000"
      >
        领取额度
      </div>
      <div class="header-swiper">
        <van-swipe
            class="my-swipe"
            :autoplay="3000"
            vertical
            :touchable="false"
            style="height: 0.6486rem"
        >
          <van-swipe-item v-for="item in 5" :key="item">
            <img
                style="max-width: 5.413rem;height: 0.373rem;"
                :src="`https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/loan-swipe-info${item}.png`"
                alt=""
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <img
          class="ai"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/ai.gif"
          alt=""
      />
    </header>
    <!-- <div class="query-options-container">
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/query-option-icon1.png"
          alt=""
          @click="queryUserInfo(0)"
      />
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/query-option-icon2.png"
          alt=""
          @click="queryUserInfo(1)"
      />
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/query-option-icon3.png"
          alt=""
          @click="queryUserInfo(2)"
      />
    </div> -->
    <div class="product-container">
      <img
          :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/product-container-title-dark.png' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/product-container-title.png'"
          alt=""
          class="product-container-title"
      />
      <div class="product-list-container">
        <div
            class="product-list-item"
            v-for="product in productList"
            :key="product.id"
        >
          <div class="product-list-item-header">
            <img
                :src="product.logo"
                alt=""
            />
            <div class="product-name">{{ product.name }}</div>
            <div class="label">{{ product.label || '今日热门' }}</div>
          </div>
          <div class="product-list-item-loan-money">
            <span>
              {{
                (
                    product.moneySection
                    && product.moneySection.split("-")[1]
                )
                || 80000
              }}
            </span>
            <em>最高可借(元)</em>
          </div>
          <div class="product-list-item-loan-time">
            最长期限
            {{
              (
                  product.loanTime
                  && product.loanTime[product.loanTime.length - 1]
              )
              || 12
            }}
            个月，年利率低至{{ product.dayRate || '6.0' }}%起
          </div>
          <div
              class="apply-button"
              @click="toApply(product)"
              v-throttle="2000"
          >
            立即申请
          </div>
        </div>
      </div>
    </div>
    <van-popup
        class="failed-pop"
        v-model="showFailedPop"
        :close-on-click-overlay="false"
    >
      <img
          :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/failed-icon-dark.png' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/failed-icon.png'"
          alt=""
      />
      <div>很抱歉，申请失败</div>
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/failed-tip.png"
          alt=""
      />
      <img
          @click="closeFailedPop"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/failed-button.png"
          alt=""
      />
    </van-popup>
    <van-popup
        class="product-agreement-pop"
        v-model="showProductAgreement"
        :close-on-click-overlay="false"
        position="bottom"
        round
    >
      <img
          @click="() => {
              showProductAgreement = false
              productAgreementPopIsAgree = true
            }"
          class="product-agreement-pop-close-icon"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/product-agreement-pop-close-icon.png"
          alt=""
      />
      <div class="head">
        <img :src="currentAgreementProduct.logo" alt="">
        <span>{{ currentAgreementProduct.name }}</span>
      </div>
      <div class="main">
        <div class="item">
          <div>
            {{
              currentAgreementProduct.moneySection
              && currentAgreementProduct.moneySection.split("-")[1]
            }}
          </div>
          <div>可借额度</div>
        </div>
        <div class="item">
          <div>
            {{
              currentAgreementProduct.loanTime
              && currentAgreementProduct.loanTime[currentAgreementProduct.loanTime.length - 1]
            }}
            <em>个月</em>
          </div>
          <div>最长期限</div>
        </div>
      </div>
      <div class="bottom">
        <img
            v-show="productAgreementPopIsAgree"
            @click="productAgreementPopIsAgree = !productAgreementPopIsAgree"
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/agree-icon.png"
            alt=""
        />
        <div
            v-show="!productAgreementPopIsAgree"
            @click="productAgreementPopIsAgree = !productAgreementPopIsAgree"
        />
        我已阅读并同意
        <em @click="showAgreement = true">《{{ currentAgreementProduct.agreementName }}》</em>
      </div>
      <div
          class="submit"
          @click="productAgreementPopSubmit"
      >
        立即申请
      </div>
    </van-popup>
    <van-popup
        v-model="showAgreement"
        round
        :style="{ width: '90%' }"
        :close-on-click-overlay="false"
    >
      <iframe
          style="overflow-y: auto;padding: 0.133rem;width: 100%;height: 50vh; font-size: 0.427rem"
          :src="currentAgreementProduct.agreementUrl"
      ></iframe>
      <div
          class="do-ok"
          @click="showAgreement = false"
      >
        好的
      </div>
    </van-popup>
    <img
        v-if="!noDownload && !showBottomCopyTextImage"
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/download-button-image-new.png"
        alt=""
        class="bottom-banner"
        @click="toDownload"
    />
    <img
        v-if="showBottomCopyTextImage"
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/copy-text-image.png"
        alt=""
        class="bottom-banner"
        @click="copyText('51卡包')"
    />
    <MyLoading :show="showLoading"/>
    <van-popup
        v-model="protocolPopupIsShow"
        position="bottom"
        :close-on-click-overlay="false"
        round
    >
      <div class="protocol-popup-container">
        <div
            class="protocol-content-container"
            ref="protocol-content-container"
        >
          <van-tabs
              v-model="protocolsTitleIndex"
              animated
              :ellipsis="false"
          >
            <van-tab
                v-for="(item, index)
                in bigAgreementName"
                :title="item"
                :key="index"
            >
              <div
                  v-if="item === '个人信息授权书' && protocolPopupIsShow"
                  v-html="protocolContentHtml"
                  :class="{
                    'protocol-content': true,
                    'protocol-content-no-scroll': stopSubmit
                  }"
                  ref="content"
              >
              </div>
              <iframe
                  v-else
                  :src="bigAgreementName.length === 2 ? bigAgreementFile[0] : bigAgreementFile[index]"
                  frameborder="0"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div
            class="protocol-do-agree-button"
            v-throttle="2000"
            @click="doSubmitApiProduct"
        >
          我同意并申请
        </div>
        <div
            class="protocol-refuse-button"
            @click="refuseApiProductProtocol"
        >
          我不同意
        </div>
      </div>
    </van-popup>
    <van-popup
        round
        v-model="refuseProtocolPopupIsShow"
        :close-on-click-overlay="false"
    >
      <div class="refuse-protocol-popup-container">
        <h3>流程太繁琐？</h3>
        <p>我们采用自动化AI匹配</p>
        <p>您所填写的资料我们会高度保密</p>
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/finish-apply-button.png"
            alt=""
            v-throttle="2000"
            @click="doSubmitApiProduct"
        />
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/to-super-loan-button.png"
            alt=""
            @click="apiProductApplyCancel"
        />
      </div>
    </van-popup>
    <div
        class="count-down-cover"
        v-show="countDownCoverIsShow"
    >
      <img
          :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image-dark.gif' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image.gif'"
          alt=""
      >
    </div>
  </div>
</template>

<script>
import { get_product_list, click_product_item } from '_network/superLoan'
import {save_personal_protocol_image, to_apply_super_loan_list_product} from '_network/product'
import { apply_big_loan_product } from '_network/product'
import { get_agreement_list } from '_network/submitInfo'
import { apply_api_product } from '_network/apiProduct'

import MyLoading from '_components/MyLoading'
import personalContent from '_utils/personalContent'
import getNowDate from '_utils/time'
import html2canvas from 'html2canvas'

export default {
  components: {
    MyLoading
  },
  data() {
    return {
      productList: [],
      topProduct: {},
      downloadUrl: '',
      showFailedPop: false,
      showLoading: false,
      noDownload: process.env.VUE_APP_IS_NO_DOWNLOAD_BOTTOM_IMG,
      // 点击申请产品时候根据产品的 agreementSwitch判断是否弹出协议
      showProductAgreement: false,
      currentAgreementProduct: {},
      productAgreementPopIsAgree: true,
      showAgreement: false,
      // 底部下载图片更换为复制文案的图片
      showBottomCopyTextImage: process.env.VUE_APP_SUPER_LOAN_REPLACE_DOWNLOAD,

      // 这里的产品只会是api产品，用于用户申请本地api产品时弹出的协议弹窗内容信息替换
      product: {},
      // 协议名称展示集合
      bigAgreementName: ['个人信息授权书'],
      // 协议展示地址集合
      bigAgreementFile: [],
      // 个人信息授权书内容
      protocolContentHtml: '',
      // 个人信息授权书弹窗显隐状态
      protocolPopupIsShow: false,
      // 多个个人信息授权书弹框列表时默认展示的协议索引值
      protocolsTitleIndex: 0,
      // 该状态在协议弹框弹出时为true，在协议截图完成时改为false，用来阻止用户滑动协议以及点击提交申请的按钮
      stopSubmit: false,
      // 产品匹配成功后3秒的遮罩层显隐
      countDownCoverIsShow: false,
      // 用户点击协议弹框的 我不同意 后弹出的确认弹框
      refuseProtocolPopupIsShow: false,
      headerClassName: process.env.VUE_APP_SUPER_LOAN_HEADER_CLASS_NAME
    }
  },
  methods: {
    async closeFailedPop() {
      this.showFailedPop = false
      this.showProductAgreement = false
      this.getProductList()
    },
    async getProductList() {
      try {
        this.showLoading = true
        const { data } = await get_product_list()
        this.showLoading = false

        if (process.env.VUE_APP_SUPER_LOAN_ONLY_LOCAL_STATE) {
          // 这条特殊渠道只要localState为1和2的产品
          const productList = data.filter((product) => product.localState === 1 || product.localState === 2)
          if (productList.length === 0) {
            // 如果1个都没有直接跳下载
            this.$toDownload(0)
          } else {
            this.productList = productList
          }
        } else {
          // 其他普通渠道正常拿产品列表
          this.productList = data || []
        }

        // 获取产品列表的第一位为顶部产品
        this.topProduct = this.productList.splice(0, 1)[0] || {}
      } catch (e) {}
    },
    //去申请
    async toApply(product) {
      if (!product.id && product.id !== 0) {
        this.$toast({
          type: 'fail',
          message: '暂无产品'
        })
        return
      }
      // 申请api产品
      if (product.productType === 2) {
        // api产品存入本地
        this.product = product

        // h5直接跳转
        if (this.product.localState === 0) {
          try {
            // api h5产品进件
            const { data } = await apply_big_loan_product({
              productId: this.product.id,
              instApiState: this.product.instApiState,
              bigLoanType: this.product.isKlhdProduct
            })
            
            localStorage.removeItem('applySubmit')
            
            if (!data.result) {
              this.$toast.fail('产品已下架')
              return
            }

            // 跳链接
            location.href = data.productDto.h5Url
          } catch (e) {}
        } else {
          try {
            // 这里要获取一次协议，根据返回的产品中的值更换公司名
            await this.getProtocolContentHtml()

            // api产品存入本地，在后续中间页需要使用
            localStorage.setItem('apiProduct', JSON.stringify(this.product))

            // 对弹出框的协议内容进行处理并弹出
            this.replaceProtocolContent()
          } catch (e) {}
        }
        return
      }
      if (product.agreementSwitch === 1) {
        this.showProductAgreement = true
        this.currentAgreementProduct = JSON.parse(JSON.stringify(product))
        this.currentAgreementProduct.agreementSwitch = 0
        return
      }
      try {
        //需要先添加1次产品的点击次数
        await click_product_item(product.id)
        if (product.type === 1) {
          if(product.h5Url) {
            location.href = product.h5Url
          }
        } else {
          //这里是联登产品
          if (product.middlePageSwitch) {
            localStorage.setItem('productItem', JSON.stringify(product))
            await this.$router.replace('/middlepage')
          } else {
            const {data} = await to_apply_super_loan_list_product(product.id)
            if (data.state) {
              this.showFailedPop = true
            } else {
              location.href = data.downUrl
            }
          }
        }
      } catch (err) {}
    },
    toDownload() {
      this.$toDownload(1)
    },
    productAgreementPopSubmit() {
      if (!this.productAgreementPopIsAgree) {
        this.$toast({
          type: 'fail',
          message: '请先阅读并勾选协议'
        })
        return
      }
      this.toApply(this.currentAgreementProduct)
    },
    // 复制文案至剪切板
    copyText(text) {
      const inputElement = document.createElement('input')
      inputElement.value = text
      document.body.appendChild(inputElement)
      inputElement.select()
      document.execCommand('copy')
      document.body.removeChild(inputElement)
      this.$toast.success('复制成功')
    },
    // 用户查询信息
    queryUserInfo(type) {
      if (process.env.VUE_APP_IS_WE_CHAT) {
        if (type === 0) {
          this.$router.push('/applyHistory')
        } else if (type === 1) {
          this.$router.push('/clearApplyHistory')
        } else {
          this.$router.push('/creditReport')
        }
        return
      }

      // 所有查看信息只能在微信公众号渠道的h5中进行，其他渠道直接跳转关注微信号的二维码页面
      this.$router.push({
        path: '/weChatCode',
        query: {
          type
        }
      })
    },
    //获取个人信息授权书协议
    async getProtocolContentHtml() {
      this.bigAgreementName = ['个人信息授权书']
      this.bigAgreementFile = []
      try {
        const { data } = await get_agreement_list()
        data.forEach((protocol) => {
          if (protocol.name === "个人信息授权书") {
            this.protocolContentHtml = protocol.content
            if (process.env.VUE_APP_IS_SPECIAL_PERSONAL) {
              this.protocolContentHtml = personalContent
            }
            if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll('{productName}', process.env.VUE_APP_PROTOCOL_PLATFORM_NAME)
            }
            if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll('{productCompanyName}', process.env.VUE_APP_PROTOCOL_COMPANY_NAME)
            }
            localStorage.setItem('agreementId', protocol.id)
          }
        })
      } catch (e) {}
    },
    // 处理协议，替换协议内容，弹出协议并获得截图
    replaceProtocolContent() {
      let protocolList = []
      if (this.product.xdApiAgreementList && this.product.xdApiAgreementList.length !== 0) {
        protocolList = this.product.xdApiAgreementList
      }
      // 把protocolList循环分别赋值给bigAgreementNameList和bigAgreementFileList
      this.product.bigAgreementName = protocolList.map((protocol) => protocol.name)
      this.product.bigAgreementFile = protocolList.map((protocol) => protocol.url)

      // 处理协议弹框顶部的多个协议
      if (this.product.bigAgreementName) {
        // api协议在获取时已经成为数组，因此不用重复调用split方法，其他为本地配置的协议时字段为字符串，因此改为数组
        const bigAgreementNameList = this.product.bigAgreementName
        const bigAgreementFileList = this.product.bigAgreementFile
        if (bigAgreementNameList.length === 1) {
          this.bigAgreementName.push(...bigAgreementNameList)
          this.bigAgreementFile.push(...bigAgreementFileList)
        } else {
          bigAgreementNameList.splice(1, 0, '个人信息授权书')
          bigAgreementFileList.splice(1, 0, '')
          this.bigAgreementName = bigAgreementNameList
          this.bigAgreementFile = bigAgreementFileList
          this.protocolsTitleIndex = 1
          this.$nextTick(() => {
            this.protocolsTitleIndex = 1
          })
        }
      }

      // 获取用户信息填入协议
      const userInfo = this.$getUserInfo()

      let userName = ''
      if (userInfo && userInfo.userName) {
        userName = userInfo.userName.split('').map((item, index) => {
          if (index === 0) {
            return item
          } else {
            return '*'
          }
        }).join('')
      }

      // 身份证号判空
      userInfo.idCard = userInfo.idCard ? userInfo.idCard : ""

      // 协议内容填充
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{name}",
          userName
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{idCard}",
          userInfo.idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1********\$2")
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{time}",
          getNowDate()
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{institutionName}",
          this.product.name || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{companyName}",
          this.product.companyName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{address}",
          this.product.address || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productName}",
          this.product.productName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productCompanyName}",
          this.product.productCompanyName || ""
      )

      // 弹出协议
      this.protocolPopupIsShow = true

      // 截图状态开始时不可以点击提交
      this.stopSubmit = true

      // 弹出协议之后进行截图，截图需要等弹出动画结束
      setTimeout(() => {
        this.html2canvas()
      }, 300)
    },
    // 通过点击协议弹窗的同意按钮申请api产品
    doSubmitApiProduct() {
      // 开启3s倒计时动画
      this.countDownCoverIsShow = true

      localStorage.setItem('submitSuccess', '1')

      // 需要一个延时器来确保动画的加载时长
      setTimeout(async () => {
        try {
          // 截图保存给后端
          const base64Img = localStorage.getItem("base64Img")
          const file = base64Img ? this.base64ToFile(base64Img, "image") : ""
          save_personal_protocol_image({
            data: file,
            ...this.product
          })

          // api产品进件
          const { data } = await apply_big_loan_product({
            productId: this.product.id,
            instApiState: this.product.instApiState,
            bigLoanType: this.product.isKlhdProduct
          })

          if (!data.result) {
            // 关闭倒计时动画，倒计时动画的z-index值会影响弹框
            this.countDownCoverIsShow = false
            // 关闭协议弹框
            this.protocolPopupIsShow = false

            this.$toast.fail('该产品已下架')

            // 刷新页面
            setTimeout(() => {
              location.reload()
            }, 1500)
            return
          }

          // 本地api产品推送
          if (this.product.localState === 1) {
            await apply_api_product(this.product.id)
          }

          // 该值代表着在api产品中间页不会再次匹配产品
          localStorage.setItem('apiProductMiddleNotMatchAgain', '1')

          // 申请后跳转中间页
          await this.$router.replace('/apiProductMiddlePage')
        } catch (e) {
          // 发生异常时关闭倒计时动画
          this.countDownCoverIsShow = false
        }
      }, 1500)
    },
    refuseApiProductProtocol() {
      // 首先关闭协议弹窗
      this.protocolPopupIsShow = false
      // 等关闭动画300ms结束，弹出再次询问的弹框
      setTimeout(() => {
        this.refuseProtocolPopupIsShow = true
      }, 300)
    },
    // 用户取消申请api产品
    apiProductApplyCancel() {
      this.refuseProtocolPopupIsShow = false
      this.getProductList()
    },
    // 截图
    getShareImgBase64() {
      return new Promise((resolve) => {
        setTimeout(() => {
          html2canvas(document.querySelector('.protocol-popup-container'), {
            // useCORS: true, // 【重要】开启跨域配置
            scale: 1
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0)
            resolve(imgData)
          })
        }, 800)
      })
    },
    html2canvas() {
      this.$nextTick(() => {
        this.getShareImgBase64().then((res) => {
          localStorage.setItem('base64Img', res)
          // 截图完成可以提交资料
          this.stopSubmit = false
        })
      })
    },
    // 转换截图为file
    base64ToFile(data, fileName) {
      const dataArray = data.split(',')
      const byteString = atob(dataArray[1])
      const options = {
        type: 'image/jpeg',
        endings: 'native'
      }
      const utf8Array = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        utf8Array[i] = byteString.charCodeAt(i)
      }
      const formData = new FormData()
      let fileOfBlob = new File([utf8Array], fileName +'.jpg', options)//返回文件流
      formData.append('image', fileOfBlob)
      return formData
    }
  },
  created() {
    window.addEventListener('pageshow', () => {
      console.log('pageshow');
      this.getProductList()
    })
    this.getProductList()
    this.showFailedPop = this.$route.params.failed
  }
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
